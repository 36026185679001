<template>
  <section class="checkout_section">
    <form action=""></form>
    <div class="container">
      <div class="checkout_container">
        <div class="col-lg-10 offset-lg-1">
          <h4 class="title">CREATE AN ACCOUNT</h4>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit="customerEnroll">
              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label>Name </label>
                        <ValidationProvider
                          rules="required|minName:3"
                          v-slot="{ errors }"
                        >
                          <input
                            class="form-control"
                            name="name"
                            v-model="user.name"
                            type="text"
                          />
                          <span style="color: red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label>Email Address </label>
                        <ValidationProvider
                          rules="email|required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            name="email"
                            v-model="user.email"
                            class="form-control"
                          />
                          <span style="color: red">{{ errors[0] }}</span>
                        </ValidationProvider>
                          <span style="color: red" v-if="resError.hasOwnProperty('email')">{{ resError.email[0] }}</span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label>Password </label>
                        <ValidationProvider
                          rules="min:6|required|"
                          vid="confirmation"
                          v-slot="{ errors }"
                        >
                          <input
                            type="password"
                            name="password"
                            v-model="user.password"
                            class="form-control"
                          />
                          <span style="color: red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="form-check">
                          <ValidationProvider
                            :rules="{ required: { allowFalse: false } }"
                            v-slot="{ errors }"
                            name="terms"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="terms"
                              name="terms"
                              v-model="user.terms"
                            />
                            <label class="form-check-label" for="terms"
                              >I agree to the
                              <span>terms & conditions</span> and the privacy
                              policy.</label
                            >
                            <br />
                            <span style="color: red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="col-md-12">
                    <div class="form-group required">
                      <label>Phone </label>

                      <ValidationProvider
                        rules="numeric|length:11|required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          name="phone"
                          v-model="user.phone"
                          class="form-control"
                        />
                        <span style="color: red">{{ errors[0] }}</span>
                      </ValidationProvider>
                        <span style="color: red" v-if="resError.hasOwnProperty('phone')">{{ resError.phone[0] }}</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="form-check" id="signup_newsletter">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="newsletter"
                        />
                        <label class="form-check-label" for="newsletter"
                          >Sing Up for Newsletter</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group required">
                      <label>Retype Password </label>

                      <ValidationProvider
                        rules="required|confirmed:confirmation"
                        v-slot="{ errors }"
                      >
                        <input
                          type="password"
                          name="passwordConfirm"
                          v-model="user.password_confirmation"
                          class="form-control"
                        />
                        <span style="color: red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <center>
                    <button
                      :disabled="invalid"
                      class="checkout_form_btn btn"
                      type="submit"
                    >
                      Sign up
                    </button>
                  </center>
                </div>
                <div class="col-md-12"><center>Or</center></div>
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <div class="others_form">
                    <ul class="btn_list">
                      <li>
                        <router-link class="loginbtn" :to="{ name: 'login' }">
                          Already signed up? Login</router-link>
                      </li>
                      <li>
                        <a @click="googleLogin" class="google" style="cursor: pointer"
                          ><i class="icon ion-logo-googleplus"></i> Continue To
                          Google</a
                        >
                      </li>
                      <li>
                        <a @click="loginFacebook" class="facebook" style="cursor: pointer"
                          ><i class="icon ion-logo-facebook"></i> Continue
                          Facebook</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3"></div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  name: "registration",
  components: {
    ValidationObserver,
  },
  data() {
    return {
      user: {
        name: "",
        email: "",
        phone: "",
        terms: "",
        password: "",
        passwordConfirm: "",
      },
      showError: false,
      resError: {
        email: [],
        phone: [],
      }
    };
  },
  watch:{
    'user.email'(oldvalue, newVal){
      if(newVal){
        this.resError.email = []
      }
    },
    'user.phone'(oldvalue, newVal){
      if(newVal){
        this.resError.phone = []
      }
    }
  },
  methods: {
    customerEnroll(e) {
      e.preventDefault();
      // console.log(this.user);
      this.$store.dispatch("registration", this.user).then((res) => {
        console.log('Data Registration = ', res);
        if(res === undefined){
          this.$toasted.success('Successfully register.');
          this.$router.push('/customer-dashboard').catch(() => {});
          console.log("Path================");
          this.user = {
            name: "",
            email: "",
            phone: "",
            terms: "",
            password: "",
            passwordConfirm: "",
          };
        }else {
          this.$toasted.error(res.message)
          this.resError = res.errors;
        }
      });

    },
    loginFacebook(){
      window.location.href='https://savershall.com/sh_backapi/login/facebook'
    },
    googleLogin(){
      window.location.href='https://savershall.com/sh_backapi/auth/google'
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/frontend/style/registration.scss";
a.loginbtn {
  background: #1f1f1f;
}
</style>
