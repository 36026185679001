<template>
  <div>
    <RegistrationSection></RegistrationSection>
  </div>
</template>
<script>
import RegistrationSection from "@/components/frontend/registration/RegistrationSection.vue";

export default {
  name: "Checkout",
  components: {
    RegistrationSection,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
@import "@/assets/frontend/style/checkout.scss";
</style>
